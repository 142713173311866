/* LINEUP */
document.addEventListener("DOMContentLoaded", function() {
    // Marquee Script
    var marqueeItems = document.querySelectorAll('div.marquee-item');

    marqueeItems.forEach(function(item) {
        for (var i = 0; i < 3; i++) {
            var clone = item.cloneNode(true);
            item.parentNode.insertBefore(clone, item.nextSibling);
        }
    });

    // Video Container Script
    const videoContainer = document.querySelector('.video-container');
    const cursorButton = document.querySelector('.cursor-button');

    videoContainer.addEventListener('mouseenter', () => {
        cursorButton.style.display = 'block';
    });

    videoContainer.addEventListener('mouseleave', () => {
        cursorButton.style.display = 'none';
    });

    videoContainer.addEventListener('mousemove', (e) => {
        const rect = videoContainer.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Positionierung des Elements zentrieren
        cursorButton.style.left = `${x - 25}px`; // 25 is half the width of the cursorButton
        cursorButton.style.top = `${y - 25}px`; // 25 is half the height of the cursorButton
    });

    // LINEUP Script
    function adjustLayout() {
        const actsContainer = document.querySelector('.acts');
        const headlinersContainer = document.querySelector('.headliners');
        const acts = document.querySelectorAll('.acts .act');
        const headliners = document.querySelectorAll('.headliners .act');

        function clearExistingRows(container) {
            const existingRows = container.querySelectorAll('.row-lineup');
            existingRows.forEach(row => row.remove());
        }

        function groupByRow(elements, containerWidth) {
            let rows = [];
            let currentRow = [];
            let currentWidth = 0;

            elements.forEach(el => {
                const elWidth = el.getBoundingClientRect().width;

                if (currentWidth + elWidth + (currentRow.length * 34) > containerWidth) { // Adjusted for 30px connector + 4px margin
                    rows.push(currentRow);
                    currentRow = [];
                    currentWidth = 0;
                }

                currentRow.push(el);
                currentWidth += elWidth + 4; // Add 4px margin to each element width
            });

            if (currentRow.length > 0) {
                rows.push(currentRow);
            }

            return rows;
        }

        function createRows(container, elements) {
            const containerWidth = container.getBoundingClientRect().width;
            const rows = groupByRow(Array.from(elements), containerWidth);

            rows.forEach(rowElements => {
                const row = document.createElement('div');
                row.className = 'row-lineup';

                rowElements.forEach((el, index) => {
                    row.appendChild(el);
                    if (index < rowElements.length - 1) {
                        const connector = document.createElement('div');
                        connector.className = 'connector';
                        row.appendChild(connector);
                    }
                });

                container.appendChild(row);
                adjustRowConnectors(row);
            });
        }

        function adjustRowConnectors(row) {
            const connectors = row.querySelectorAll('.connector');
            const totalWidth = row.getBoundingClientRect().width;
            let totalTextWidth = 0;

            row.querySelectorAll('.act').forEach(act => {
                totalTextWidth += act.getBoundingClientRect().width;
            });

            let totalConnectorWidth = totalWidth - totalTextWidth;
            let connectorWidth = totalConnectorWidth / connectors.length - 20; // Subtract 4px from each connector width

            if (connectorWidth < 30) {
                connectorWidth = 30;
            }

            connectors.forEach(connector => {
                connector.style.width = `${connectorWidth}px`;
            });

            if (connectorWidth * connectors.length + totalTextWidth > totalWidth) {
                const newConnectorWidth = (totalWidth - totalTextWidth) / connectors.length - 20; // Subtract 4px from each connector width
                connectors.forEach(connector => {
                    connector.style.width = `${newConnectorWidth}px`;
                });
            }
        }

        // Clear existing rows
        clearExistingRows(headlinersContainer);
        clearExistingRows(actsContainer);

        // Create rows for headliners and acts
        createRows(headlinersContainer, headliners);
        createRows(actsContainer, acts);
    }

    // Initial call to adjust the layout
    adjustLayout();
});

